/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* 10X Games - v1.1.0
=========================================================

* Product Page: https://www.10X-ui.com/
* Copyright 2023 10X Games (https://www.10X-ui.com/)

* Designed and Coded by 10X Games

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Upload from "views/admin/profile/components/Upload";

// Assets

import avatar from "assets/img/avatars/avatar4.png";
import React from "react";
import ProfileCard from "./components/ProfileCard";

export default function Overview() {
  return (

        <ProfileCard 
        avatar={avatar}
        name='Jugnu Sattebazz'
        job='Juwari , Satebazz , Paisewala'
        ranking='17'
        coins_earned='9.7k'
        total_coins='274' >
        
        </ProfileCard>
  );
}
