export const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Artworks",
    accessor: "artworks",
  },
  {
    Header: "Rating",
    accessor: "rating",
  },
];
