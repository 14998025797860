import React from 'react';
import './ProfileCard.css';

const ProfileCard = ({ banner, avatar, name, job, ranking, coins_earned , total_coins}) => {
  return (
    <div className="profile-card-container grid-column: auto"  >
      <div className="card">
        <div className="banner" style={{ backgroundImage: `url(${banner})` }} />
        <img className="avatar" src={avatar} alt="Avatar" />
        <div className="info">
          <h2 className="name">{name}</h2>
          <p className="job">{job}</p>
        </div>
        <div className="stats">
          <div className="stat">
            <p className="stat-number">{ranking}</p>
            <p className="stat-label">Ranking</p>
          </div>
          <div className="stat">
            <p className="stat-number">{coins_earned}</p>
            <p className="stat-label">Winning Coins</p>
          </div>
          <div className="stat">
            <p className="stat-number">{total_coins}</p>
            <p className="stat-label">Total</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
