

import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import heroBannerBG from "assets/img/images/hero-banner-bg.png";
import heroBanner from "assets/img/images/hero-banner.png"
import backgroundImage from "assets/img/images/body-bg.jpg"
import teamLogo1 from "assets/img/images/team-logo-1.png"
import teamLogo2 from "assets/img/images/team-logo-2.png"
import teamLogo3 from "assets/img/images/team-logo-3.png"
import teamLogo4 from "assets/img/images/team-logo-4.png"
import teamLogo5 from "assets/img/images/team-logo-5.png"
import teamLogo6 from "assets/img/images/team-logo-6.png"
import logo from "assets/img/images/logo10.png"

import { FaFacebook, FaInstagram, FaTwitter, FaYoutube,FaArrowUp } from 'react-icons/fa';

import './style.css'
// Custom Chakra theme
export default function Home(props) {
  useEffect(()=>{
    const addEventOnElements = function (elements, eventType, callback) {
      for (let i = 0, len = elements.length; i < len; i++) {
        elements[i].addEventListener(eventType, callback);
      }
    }
  
  
    const navbar = document.querySelector("[data-navbar]");
    const navToggler = document.querySelector("[data-nav-toggler]");
    const navLinks = document.querySelectorAll("[data-nav-link]");
  
    const toggleNav = function () {
      navbar.classList.toggle("active");
      this.classList.toggle("active");
    }
  
    navToggler.addEventListener("click", toggleNav);
  
    const navClose = () => {
      navbar.classList.remove("active");
      navToggler.classList.remove("active");
    }
  
    addEventOnElements(navLinks, "click", navClose);
  
  
  
    const header = document.querySelector("[data-header]");
    const backTopBtn = document.querySelector("[data-back-top-btn]");
  
    const activeEl = function () {
      if (window.scrollY > 100) {
        header.classList.add("active");
        backTopBtn.classList.add("active");
      } else {
        header.classList.remove("active");
        backTopBtn.classList.remove("active");
      }
    }
  
    window.addEventListener("scroll", activeEl);
  
  
    const buttons = document.querySelectorAll("[data-btn]");
  
    const buttonHoverRipple = function (event) {
      this.style.setProperty("--top", `${event.offsetY}px`);
      this.style.setProperty("--left", `${event.offsetX}px`);
    }
  
    addEventOnElements(buttons, "mousemove", buttonHoverRipple);
  
    const revealElements = document.querySelectorAll("[data-reveal]");
  
    const revealElementOnScroll = function () {
      for (let i = 0, len = revealElements.length; i < len; i++) {
        const isElementInsideWindow = revealElements[i].getBoundingClientRect().top < window.innerHeight / 1.1;
  
        if (isElementInsideWindow) {
          revealElements[i].classList.add("revealed");
        }
      }
    }
  
    window.addEventListener("scroll", revealElementOnScroll);
  
    window.addEventListener("load", revealElementOnScroll);
  
  
    const cursor = document.querySelector("[data-cursor]");
    const hoverElements = [...document.querySelectorAll("a"), ...document.querySelectorAll("button")];
  
    const cursorMove = function (event) {
      cursor.style.top = `${event.clientY}px`;
      cursor.style.left = `${event.clientX}px`;
    }
  
    window.addEventListener("mousemove", cursorMove);
  
    addEventOnElements(hoverElements, "mouseover", function () {
      cursor.classList.add("hovered");
    });
  
    addEventOnElements(hoverElements, "mouseout", function () {
      cursor.classList.remove("hovered");
    });
  },[])
  return (
      <div style={{ backgroundImage:`url(${backgroundImage})`,backgroundRepeat:"repeat" }}>
        <Helmet>
            {/* Link to Google Fonts */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
              href="https://fonts.googleapis.com/css2?family=Oxanium:wght@400;500;600;700&family=Work+Sans:wght@600&display=swap"
              rel="stylesheet"
            />
        </Helmet>
            <header className="header active" data-header>
            <div className="container">

              <a href="#" className="logo">
                <img src={logo} width="110" height="53" alt="unigine home"/>
              </a>

              <nav className="navbar" data-navbar>
                <ul className="navbar-list">

                  <li className="navbar-item">
                    <a href="#home" className="navbar-link" data-nav-link>home</a>
                  </li>

                  <li className="navbar-item">
                    <a href="#tournament" className="navbar-link" data-nav-link>Giveaway's</a>
                  </li>

                  <li className="navbar-item">
                    <a href="#contact" className="navbar-link" data-nav-link>contact</a>
                  </li>

                </ul>
              </nav>

              <a href="/auth/sign-in" className="btn" data-btn>join our team</a>

              <button className="nav-toggle-btn" aria-label="toggle menu" data-nav-toggler>
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
              </button>

            </div>
          </header>
          <main>
            <article>

              <div className="hero has-before" id="home">
                <div className="container">

                  <p className="section-subtitle">Enjoy The Games</p>

                  <h1 className="h1 title hero-title">10x Games Made For <br/> True Gamers!</h1>

                  <a href="/auth/sign-in" className="btn" data-btn>Join With Us</a>

                  <div className="hero-banner">
                    <img src={heroBanner} width="850" height="414" alt="hero banner" className="w-100"/>

                    <img src={heroBannerBG} alt="" className="hero-banner-bg"/>
                  </div>

                </div>
              </div>


              <section className="section upcoming" aria-labelledby="upcoming-label" id="tournament">
                <div className="container">

                  <p className="section-subtitle" id="upcoming-label" data-reveal="bottom">
                    Upcoming Giveaway
                  </p>

                  <h2 className="h2 section-title" data-reveal="bottom">
                    Battles Extreme <br/> Masters <span className="span">Giveaway</span>
                  </h2>

                  <p className="section-text" data-reveal="bottom">
                    In the Proces to Make Games Easy to Play And Win
                  </p>

                  <ol className="upcoming-list">

                    <li className="upcoming-item">

                      <div className="upcoming-card left has-before" data-reveal="left">

                        <img src={teamLogo1} width="86" height="81" loading="lazy"
                          alt="Purple Death Cadets" className="card-banner"/>

                        <h3 className="h3 card-title">Magenta Eagle ($10k)</h3>

                        <div className="card-meta">Leaderboard Top</div>

                      </div>

                      <div className="upcoming-time" data-reveal="bottom">
                        <h2 className="time" >NFT's Giveaway</h2>
                      </div>

                      <div className="upcoming-card right has-before" data-reveal="right">

                        <img src={teamLogo2} width="86" height="81" loading="lazy"
                          alt="Trigger Brain Squad" className="card-banner"/>

                        <h3 className="h3 card-title">Dyno Jitsu ($5K)</h3>

                        <div className="card-meta">Max Match Won</div>

                      </div>

                    </li>

                    <li className="upcoming-item">

                      <div className="upcoming-card left has-before" data-reveal="left">

                        <img src={teamLogo3} width="86" height="81" loading="lazy"
                          alt="The Black Hat Hackers" className="card-banner" />

                        <h3 className="h3 card-title">Kobra Kai  ($2k)</h3>

                        <div className="card-meta">Max Game Played</div>

                      </div>

                      <div className="upcoming-time" data-reveal="bottom">
                        <h2 className="time" >NFT's Giveaway</h2>
                      </div>

                      <div className="upcoming-card right has-before" data-reveal="right">

                        <img src={teamLogo4} width="86" height="81" loading="lazy"
                          alt="Your Worst Nightmare" className="card-banner"/>

                        <h3 className="h3 card-title">White Eagles ($1k)</h3>

                        <div className="card-meta">Max Match Loose</div>

                      </div>

                    </li>
                  </ol>

                </div>
              </section>

            </article>
          </main>

          <footer id="contact" className="footer">

            <div className="section footer-top">
              <div className="container">

                <div className="footer-brand">

                  <a href="#" className="logo">
                    <img src={logo} width="150" height="73" loading="lazy" alt="Unigine logo"/>
                  </a>

                  <p className="footer-text">
                    In the Proces to Make Games Easy to Play And Win
                  </p>

                  <ul className="social-list">

                    <li>
                      <a href="#" className="social-link">
                        <FaFacebook size={32} />
                      </a>
                    </li>

                    <li>
                      <a href="#" className="social-link">
                      <FaTwitter size={32} />
                      </a>
                    </li>

                    <li>
                      <a href="#" className="social-link">
                      <FaInstagram size={32} />
                      </a>
                    </li>

                    <li>
                      <a href="#" className="social-link">
                       <FaYoutube size={32} />
                      </a>
                    </li>

                  </ul>

                </div>

                <div className="footer-list">

                  <p className="title footer-list-title has-after">Usefull Links</p>

                  <ul>

                    <li>
                      <a href="#" className="footer-link">Tournaments</a>
                    </li>

                    <li>
                      <a href="#" className="footer-link">Help Center</a>
                    </li>

                    <li>
                      <a href="#" className="footer-link">Privacy and Policy</a>
                    </li>

                    <li>
                      <a href="#" className="footer-link">Terms of Use</a>
                    </li>

                    <li>
                      <a href="#" className="footer-link">Contact Us</a>
                    </li>

                  </ul>

                </div>

                <div className="footer-list">

                  <p className="title footer-list-title has-after">Contact Us</p>

                  <div className="contact-item">
                    <span className="span">Location:</span>

                    <address className="contact-link">
                      153 Williamson Plaza, Maggieberg, MT 09514
                    </address>
                  </div>

                  <div className="contact-item">
                    <span className="span">Join Us:</span>

                    <a href="mailto:Info@unigine.com" className="contact-link">Info@10x.com</a>
                  </div>

                  <div className="contact-item">
                    <span className="span">Phone:</span>

                    <a href="tel:+12345678910" className="contact-link">+1 (234) 567-8910</a>
                  </div>

                </div>

                {/* <div className="footer-list">

                  <p className="title footer-list-title has-after">Newsletter Signup</p>

                  <form action="./index.html" method="get" className="footer-form">
                    <input type="email" name="email_address" required placeholder="Your Email" 
                      className="input-field"/>

                    <button type="submit" className="btn" data-btn>Subscribe Now</button>
                  </form>

                </div> */}

               </div>
            </div>

            <div className="footer-bottom">
              <div className="container">

                <p className="copyright">
                  &copy; 2024 10X Games All Rights Reserved.
                </p>

              </div>
            </div>

          </footer>

          <a href="#top" className="back-top-btn" aria-label="back to top" data-back-top-btn>
          <FaArrowUp size={32} style={{color: "white"}}/>
          </a>

          <div className="cursor" data-cursor></div>

            <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
            <script src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
          </div>
            );    
      }
